import React, { useState } from "react";

import { connect } from "react-redux";

import { PaystackButton } from "react-paystack";

import logo from "../../assets/GADGET VILLA LOGO.jpeg";

import { toggleModal } from "../../redux/PaymentModal/modal-actions";
import { emptyCart } from "../../redux/ShoppingCart/cart-actions";

import "./PaymentModal.styles.scss";

const PaymentModal = ({
  cartItems,
  total,
  togglePaymentModal,
  emptyCartItems,
}) => {
  const [formData, SetFromData] = useState({
    email: "",
    name: "",
  });

  // LIVE KEY FOR PAYSTACK
  const publicKey = "pk_live_1c4c655f914bee4235d5a239c246dd9d4e16ec1c";

  // TEST KEY FOR PAYSTACK
  // const publicKey = "pk_test_aa63e765a35d91d34856597ffffae876c93cdc44";

  // const totalInPesewas = total / 100;

  const componentProps = {
    email: formData.email,
    name: formData.name,
    amount: total * 100,
    publicKey: publicKey,
    currency: "GHS",
    text: "PAY NOW",
    metadata: {
      cart_id: 398,
      custom_fields: [
        {
          display_name: "Cart Items",
          variable_name: "cart_items",
          value: cartItems.map((item) => {
            return `{Item : ${item.Title},Description : ${item.Description}, Price : ${item.Price}, Quantity : ${item.quantity}}`;
          }),
        },
      ],
    },
    onSuccess: () => {
      alert("Thank you for doing business with GadgetVilla! Come back soon!!");
      togglePaymentModal();
      emptyCartItems();
    },
    // onClose: () => alert("Wait! You need this oil, don't go!!!!"),
  };

  const onChange = (e) => {
    SetFromData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="payment-modal-container">
      <img src={logo} alt="" />
      <h1 className="checkout-header">CHECKOUT</h1>
      <div className="payment-divider">
        <div className="product-billing">
          <h3>BILLING DETAILS</h3>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className="payment-form"
          >
            <label htmlFor="payment-name">Name*</label>
            <input
              type="text"
              name="name"
              id="payment-name"
              value={formData.name}
              placeholder="Enter your name"
              required
              onChange={(e) => {
                onChange(e);
              }}
            />
            <label htmlFor="payment-email">Email*</label>
            <input
              type="text"
              name="email"
              id="payment-email"
              value={formData.email}
              placeholder="Enter your email address"
              required
              onChange={(e) => {
                onChange(e);
              }}
            />
          </form>
          <span className="payment-total">
            TOTAL : GH&#8373; {new Intl.NumberFormat().format(total)}
          </span>

          <PaystackButton className="paystack-button" {...componentProps} />

          <span className="payment-fine-print">
            Make your payment directly into our bank account. Your order won’t
            be shipped until the funds have cleared in our account. An
            electronic receipt will be sent to your email address. Thank you for
            your purchase.
          </span>

          {/* <img className='momo-logo' src={momo} alt='' /> */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cart.cartItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    togglePaymentModal: () => {
      dispatch(toggleModal());
    },
    emptyCartItems: () => {
      dispatch(emptyCart());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentModal);
