import { combineReducers } from 'redux';
import DrawerReducer from './CheckoutDrawer/drawer-reducer';
import CartReducer from './ShoppingCart/cart-reducer';
import PaymentModalReducer from './PaymentModal/modal-reducer';
import MobileNavBarReducer from './MobileNavBar/mobileNavBar-reducer';

const rootReducer = combineReducers({
  cart: CartReducer,
  drawer: DrawerReducer,
  modal: PaymentModalReducer,
  mobileNavBar: MobileNavBarReducer,
});

export default rootReducer;
