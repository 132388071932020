import { ADD_ITEM_TO_CART, CLEAR_ITEM_FROM_CART, SUBTRACT_ITEM_FROM_CART, EMPTY_CART } from './cart-constants';
import { addItemToCart, subtractItemFromCart } from './cart-utlity';

const initialState = {
  cartItems: [],
};

const CartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ITEM_TO_CART:
      return {
        ...state,
        cartItems: addItemToCart(state.cartItems, action.payload),
      };
    case SUBTRACT_ITEM_FROM_CART:
      return {
        ...state,
        cartItems: subtractItemFromCart(state.cartItems, action.payload),
      };
    case CLEAR_ITEM_FROM_CART:
      return {
        ...state,
        cartItems: state.cartItems.filter((cartItem) => {
          return cartItem.id !== action.payload.id;
        }),
      };
    case EMPTY_CART:
      return {
        ...state,
        cartItems: [],
      };
    default:
      return state;
  }
};

export default CartReducer;
