import { TOGGLE_DRAWER } from './drawer-constants';

const initialState = {
  drawerState: false,
};

const DrawerReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_DRAWER:
      return {
        ...state,
        drawerState: !state.drawerState,
      };
    default:
      return state;
  }
};

export default DrawerReducer;
