import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { lazy, Suspense } from "react";
import Spinner from "./assets/loading.gif";

import Navbar from "./components/Navbar/Navbar.component";
import Footer from "./components/Footer/Footer.component";
import WAButton from "./components/WhatsappButton/WAppButton.component";

// import Homepage from "./pages/HomePage/HomePage.component";
// import DetailsPage from "./pages/DetailsPage/DetailsPage.component";
// import PhonesPage from "./pages/PhonesPage/PhonesPage.component";
// import LaptopsPage from "./pages/LaptopsPage/LaptopsPage.component";
// import ConsolesPage from "./pages/ConsolesPage/ConsolesPage.Component";
// import AccessoriesPage from "./pages/AccessoriesPage/AccessoriesPage.component";
// import ContactPage from "./pages/ContactPage/ContactPage.component";
// import IOSPhonesPage from "./pages/IOSPhonesPage/IOSPhonesPage.component";
// import UsedIOSPhonesPage from "./pages/UsedIOSPhonesPage/UsedIOSPhonesPage.component";
// import AndroidPhonesPage from "./pages/UsedAndroidPhonesPage/UsedAndroidPhonesPage.component";
// import UsedAndroidPhonesPage from "./pages/UsedAndroidPhonesPage/UsedAndroidPhonesPage.component";

const Homepage = lazy(() => import("./pages/HomePage/HomePage.component"));
const DetailsPage = lazy(() =>
  import("./pages/DetailsPage/DetailsPage.component")
);
const PhonesPage = lazy(() =>
  import("./pages/PhonesPage/PhonesPage.component")
);
const LaptopsPage = lazy(() =>
  import("./pages/LaptopsPage/LaptopsPage.component")
);
const ConsolesPage = lazy(() =>
  import("./pages/ConsolesPage/ConsolesPage.Component")
);
const AccessoriesPage = lazy(() =>
  import("./pages/AccessoriesPage/AccessoriesPage.component")
);
const ContactPage = lazy(() =>
  import("./pages/ContactPage/ContactPage.component")
);
const IOSPhonesPage = lazy(() =>
  import("./pages/IOSPhonesPage/IOSPhonesPage.component")
);
const UsedIOSPhonesPage = lazy(() =>
  import("./pages/UsedIOSPhonesPage/UsedIOSPhonesPage.component")
);
const AndroidPhonesPage = lazy(() =>
  import("./pages/AndroidPhonesPage/AndroidPhones.component")
);
const UsedAndroidPhonesPage = lazy(() =>
  import("./pages/UsedAndroidPhonesPage/UsedAndroidPhonesPage.component")
);

function App() {
  return (
    <Router>
      <Navbar />
      <WAButton />
      <Switch>
        <Suspense
          fallback={
            <div>
              <img src={Spinner} alt="banner" />
            </div>
          }
        >
          <Route exact path="/" component={Homepage} />
          <Route exact path="/detailsPage/:name/:id" component={DetailsPage} />
          <Route exact path="/phones" component={PhonesPage} />
          <Route exact path="/laptops" component={LaptopsPage} />
          <Route exact path="/consoles" component={ConsolesPage} />
          <Route exact path="/accessories" component={AccessoriesPage} />
          <Route exact path="/contact" component={ContactPage} />
          <Route exact path="/IOSPhones" component={IOSPhonesPage} />
          <Route exact path="/UsedIOSPhones" component={UsedIOSPhonesPage} />
          <Route exact path="/AndroidPhones" component={AndroidPhonesPage} />
          <Route
            exact
            path="/UsedAndroidPhones"
            component={UsedAndroidPhonesPage}
          />
        </Suspense>
      </Switch>

      <Footer />
    </Router>
  );
}

export default App;
