import React from "react";

import "./CheckoutPreview.styles.scss";

import { connect } from "react-redux";
import { toggleModal } from "../../redux/PaymentModal/modal-actions";

import CheckoutItem from "../checkout-item/CheckoutItem.component";
import PaymentModal from "../paymentModal/PaymentModal.component";

import Modal from "@material-ui/core/Modal";

const CheckoutPreview = (props) => {
  const { cartItemsInCart, togglePaymentModal, PaymentModalState } = props;

  if (cartItemsInCart.length === 0) {
    return <h2 className="please-add-to-cart">Please add items to cart</h2>;
  } else {
    let total = 0;
    cartItemsInCart.map((item) => {
      let itemTotal = item.Price * item.quantity;

      return (total = total + itemTotal);
    });

    return (
      <div className="checkout-preview-container">
        {cartItemsInCart.map((item) => {
          return <CheckoutItem key={item.id} product={item} />;
        })}
        <div className="checkout-total">
          <span className="total">TOTAL :</span>
          <span className="price">
            GH&#8373;{new Intl.NumberFormat().format(total)}
          </span>
        </div>
        <button
          onClick={() => {
            togglePaymentModal();
          }}
          className="checkout-preview-button"
        >
          PROCEED TO CHECKOUT
        </button>
        <Modal
          open={PaymentModalState}
          onClose={togglePaymentModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {<PaymentModal total={total} />}
        </Modal>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    cartItemsInCart: state.cart.cartItems,
    PaymentModalState: state.modal.paymentModalState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    togglePaymentModal: () => {
      dispatch(toggleModal());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPreview);
