import React from "react";

import "./CheckoutDrawer.styles.scss";

import CheckoutPreview from "../checkout-preview/CheckoutPreview.component";

import { connect } from "react-redux";
import { toggleDrawer } from "../../redux/CheckoutDrawer/drawer-actions";

import { Drawer } from "@material-ui/core";

const CheckoutDrawer = (props) => {
  const { drawerState, toggleDrawerState } = props;
  return (
    <Drawer anchor="right" open={drawerState} onClose={toggleDrawerState}>
      <div className="shopping-cart-container">
        <div className="shopping-cart-nav">
          <h1 className="shopping-cart-header">
            <i className="bi bi-cart2"></i>Shopping Cart
          </h1>
          <button
            onClick={() => {
              toggleDrawerState();
            }}
          >
            <i className="bi bi-x-lg"></i>
          </button>
        </div>
        <CheckoutPreview />
      </div>
    </Drawer>
  );
};

const mapStateToProps = (state) => {
  return {
    drawerState: state.drawer.drawerState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleDrawerState: () => {
      dispatch(toggleDrawer());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutDrawer);
