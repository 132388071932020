import React from "react";

import "./MobileNavBar.styles.scss";

import { connect } from "react-redux";
import { toggleMobileNavBar } from "../../redux/MobileNavBar/mobileNavBar-action";

import { useHistory } from "react-router-dom";

import { Drawer } from "@material-ui/core";

const MobileNavBar = (props) => {
  const { mobileNavBarState, toggleMobileNavBar } = props;

  const history = useHistory();
  return (
    <Drawer anchor="left" open={mobileNavBarState} onClose={toggleMobileNavBar}>
      <div className="mobile-nav-container">
        <h1>MENU</h1>
        <div className="mobile-nav-links">
          <ul>
            <li
              onClick={() => {
                history.push("/");
                toggleMobileNavBar();
              }}
            >
              <i className="bi bi-house">
                <p>HOME</p>
              </i>
              <i className="bi bi-chevron-right"></i>
            </li>

            <li
              onClick={() => {
                history.push("/phones");
                toggleMobileNavBar();
              }}
            >
              <i className="bi bi-phone">
                <p>PHONES</p>
              </i>
              <i className="bi bi-chevron-right"></i>
            </li>

            <li
              onClick={() => {
                history.push("/laptops");
                toggleMobileNavBar();
              }}
            >
              <i className="bi bi-laptop">
                <p>LAPTOPS / IPADS</p>
              </i>
              <i className="bi bi-chevron-right"></i>
            </li>

            <li
              onClick={() => {
                history.push("/consoles");
                toggleMobileNavBar();
              }}
            >
              <i className="bi bi-controller">
                <p>CONSOLES</p>
              </i>
              <i className="bi bi-chevron-right"></i>
            </li>

            <li
              onClick={() => {
                history.push("/accessories");
                toggleMobileNavBar();
              }}
            >
              <i className="bi bi-smartwatch">
                <p>ACCESSORIES</p>
              </i>
              <i className="bi bi-chevron-right"></i>
            </li>
          </ul>
        </div>
      </div>
    </Drawer>
  );
};

const mapStateToProps = (state) => {
  return {
    mobileNavBarState: state.mobileNavBar.mobileNavBarState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleMobileNavBar: () => {
      dispatch(toggleMobileNavBar());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileNavBar);
