import { ADD_ITEM_TO_CART, CLEAR_ITEM_FROM_CART, SUBTRACT_ITEM_FROM_CART, EMPTY_CART } from './cart-constants';

export const addItemToCart = (item) => {
  return {
    type: ADD_ITEM_TO_CART,
    payload: item,
  };
};

export const clearItemFromCart = (item) => {
  return {
    type: CLEAR_ITEM_FROM_CART,
    payload: item,
  };
};

export const subtractItemFromCart = (item) => {
  return {
    type: SUBTRACT_ITEM_FROM_CART,
    payload: item,
  };
};

export const emptyCart = () => {
  return {
    type: EMPTY_CART,
  };
};
