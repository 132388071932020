import React from 'react';

import './CheckoutItem.styles.scss';

import { connect } from 'react-redux';

import { clearItemFromCart, subtractItemFromCart, addItemToCart } from '../../redux/ShoppingCart/cart-actions';

const CheckoutItem = (props) => {
  const { product, clearItemFromShoppingCart, subtractItemFromshoppingCart, addItemToShoppingCart } = props;

  return (
    <div className='checkout-item'>
      <div className='image-container'>
        <img src={product.Image.url} alt='item' />
      </div>
      <div className='checkout-product-info'>
        <span className='product-name'>{product.Title}</span>
        <span className='product-description'>{product.Description}</span>
        {product.Capacity ? <h5> Capacity : {product.Capacity}</h5> : null}
        {product.Color ? <h5> Color : {product.Color}</h5> : null}
        <div className='checkout-product-quantity'>
          <span
            className='minus'
            onClick={() => {
              subtractItemFromshoppingCart(product);
            }}
          >
            <i className='bi bi-dash'></i>
          </span>
          <span className='number'>{product.quantity}</span>
          <span
            className='plus'
            onClick={() => {
              addItemToShoppingCart(product);
            }}
          >
            <i className='bi bi-plus'></i>
          </span>
        </div>
        <div className='checkout-product-price'>
          <span className='quantity'>
            {product.quantity}
            {'        x'}
          </span>
          <span className='price'>
            {'   '}GH&#8373;
            {new Intl.NumberFormat().format(product.Price)}
          </span>
        </div>
      </div>
      <div
        className='checkout-product-clear-from-cart'
        onClick={() => {
          clearItemFromShoppingCart(product);
        }}
      >
        <i className='bi bi-x-circle'></i>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearItemFromShoppingCart: (item) => {
      return dispatch(clearItemFromCart(item));
    },
    subtractItemFromshoppingCart: (item) => {
      return dispatch(subtractItemFromCart(item));
    },
    addItemToShoppingCart: (item) => {
      return dispatch(addItemToCart(item));
    },
  };
};

export default connect(null, mapDispatchToProps)(CheckoutItem);
