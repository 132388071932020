import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import rootReducer from './RootReducer';

// Check if the environment is in production mode
const isProduction = process.env.NODE_ENV === 'production';

// Only apply the logger middleware in development mode
const middlewares = isProduction ? [] : [logger];

const store = createStore(rootReducer, applyMiddleware(...middlewares));

export default store;
