import { TOGGLE_MOBILE_NAV_BAR } from './mobileNavBar-constants';

const initialState = {
  mobileNavBarState: false,
};

const MobileNavBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MOBILE_NAV_BAR:
      return {
        ...state,
        mobileNavBarState: !state.mobileNavBarState,
      };
    default:
      return state;
  }
};

export default MobileNavBarReducer;
