import React from 'react';

import './Footer.styles.scss';

import logo from '../../assets/GADGET VILLA LOGO.jpeg';

const Footer = () => {
  return (
    <div className='footer-container'>
      <div className='footer-details-container'>
        <div className='footer-left'>
          <div className='footer-logo-container'>
            <img src={logo} alt='Logo' />
          </div>
          <div className='footer-tagline'>
            <h2>Home of quality and affordable gadgets.</h2>
          </div>
          <p>
            We offer a wide range of Phones, Laptops, Speakers and Accessories
            from brands like Apple, Samsung, JBL, Dell, HP, Asus and many more.
          </p>
          <h2 className='h2-tagline'>We sell, swap, buy and repair.</h2>
        </div>
        <div className='footer-middle'>
          {/* <h2>We sell, swap, buy and repair.</h2> */}
          <div className='footer-info'>
            <i className='bi bi-patch-check'></i>
            <h3>Warranty</h3>
            <h4>3 months warranty available</h4>
          </div>
          <div className='footer-info'>
            <i className='bi bi-truck'></i>
            <h3>Nationwide delivery available</h3>
            <h4>Free delivery within Accra, within 1 - 24hrs</h4>
          </div>
          <div className='footer-info'>
            <i className='bi bi-wallet2'></i>
            <h3>Easy payment methods</h3>
            <h4>Mobile money, Visa, MasterCard, Bank Transfer etc...</h4>
          </div>
        </div>
        <div className='footer-right'>
          {/* <h2>Contact Us.</h2> */}

          <div className='footer-icons'>
            <div className='footer-icons-div'>
              <i className='bi bi-telephone-forward'></i>
              <a href='tel:233247277204' target='_blank' rel='noreferrer'>
                0247277204
              </a>
            </div>
            <div className='footer-icons-div'>
              <i className='bi bi-envelope'></i>
              <a
                href='mailto:gadgetvilla1@gmail.com.'
                target='_blank'
                rel='noreferrer'
              >
                gadgetvilla1@gmail.com
              </a>
            </div>
            <div className='footer-icons-div'>
              <i className='bi bi-instagram'></i>
              <a
                href='https://www.instagram.com/gadgetvillagh/'
                target='_blank'
                rel='noreferrer'
              >
                @gadgetvillagh
              </a>
            </div>

            <div className='footer-icons-div'>
              <i className='bi bi-geo-alt'></i>
              <a
                href='https://www.google.com/maps/place/Kpogas+Furniture+-+Spintex/@5.6564822,-0.267619,12z/data=!4m9!1m2!2m1!1skpogas!3m5!1s0xfdf847025b2411d:0xc63d66a137251589!8m2!3d5.6355775!4d-0.1011036!15sCgZrcG9nYXNaCCIGa3BvZ2FzkgEPZnVybml0dXJlX3N0b3Jl'
                target='_blank'
                rel='noreferrer'
              >
                Spintex Road
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='copyright-footer'>
        <p>
          GADGETVILLA &#169; 2021 Developed by{' '}
          <a
            href='https://www.kwabena-agyeman.me/'
            target='_blank'
            rel='noreferrer'
          >
            KWAMANI
          </a>{' '}
        </p>
      </div>
    </div>
  );
};

export default Footer;
