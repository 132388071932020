import React from "react";

import "./Navbar.styles.scss";

import logo from "../../assets/GADGET VILLA LOGO.jpeg";

import { NavLink } from "react-router-dom";

import { connect } from "react-redux";
import { toggleDrawer } from "../../redux/CheckoutDrawer/drawer-actions";
import { toggleMobileNavBar } from "../../redux/MobileNavBar/mobileNavBar-action";

import MobileNavBarComponent from "../MobileNavBar/MobileNavBar.component";
import CheckoutDrawerComponent from "../checkout-drawer/CheckoutDrawer.component";

const Navbar = (props) => {
  const { toggleDrawerState, cartItems, toggleMobileNavBarState } = props;

  let quantity = 0;
  cartItems.map((item) => {
    return (quantity = quantity + item.quantity);
  });

  return (
    <div className="navbar">
      <div className="nav-mobile-menu">
        <i
          onClick={() => {
            toggleMobileNavBarState();
          }}
          className="bi bi-list"
        ></i>
        <MobileNavBarComponent />
      </div>

      <NavLink to="/">
        <div className="nav-logo-container">
          <img src={logo} alt="Logo" />
        </div>
      </NavLink>
      <div className="nav-links">
        <ul>
          <NavLink
            to="/phones"
            className="react-nav-links"
            activeStyle={{
              borderBottom: "2px solid #e85a0c ",
              color: "#e85a0c",
            }}
          >
            <i className="bi bi-phone"></i>
            Phones
          </NavLink>
          <NavLink
            to="/laptops"
            className="react-nav-links"
            activeStyle={{
              borderBottom: "2px solid #e85a0c ",
              color: "#e85a0c",
            }}
          >
            <i className="bi bi-laptop"></i>
            Laptops / Ipads
          </NavLink>
          <NavLink
            to="/consoles"
            className="react-nav-links"
            activeStyle={{
              borderBottom: "2px solid #e85a0c ",
              color: "#e85a0c",
            }}
          >
            <i className="bi bi-controller"></i>
            Consoles
          </NavLink>
          <NavLink
            to="/accessories"
            className="react-nav-links"
            activeStyle={{
              borderBottom: "2px solid #e85a0c ",
              color: "#e85a0c",
            }}
          >
            <i className="bi bi-smartwatch"></i>
            Accessories
          </NavLink>
          <NavLink
            to="/contact"
            className="react-nav-links"
            activeStyle={{
              borderBottom: "2px solid #e85a0c ",
              color: "#e85a0c",
            }}
          >
            <i className="bi bi-envelope"></i>
            Contact Us
          </NavLink>
        </ul>
      </div>
      <div className="nav-shopping-cart">
        <span className="cart-badge-quantity">{quantity}</span>
        <i
          style={{ cursor: "pointer" }}
          onClick={() => {
            toggleDrawerState();
          }}
          className="bi bi-cart3"
        ></i>
      </div>
      <CheckoutDrawerComponent />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleDrawerState: () => {
      dispatch(toggleDrawer());
    },
    toggleMobileNavBarState: () => {
      dispatch(toggleMobileNavBar());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cart.cartItems,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
