import { TOGGLE_MODAL } from './modal-constants';

const initialState = {
  paymentModalState: false,
};

const PaymentModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        paymentModalState: !state.paymentModalState,
      };
    default:
      return state;
  }
};

export default PaymentModalReducer;
