import React from "react";

import "./WAButton.styles.scss";

const WAButton = () => {
  return (
    <a
      href="https://wa.me/233247277204"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="whatsappButton-wrapper animate__animated  animate__bounce">
        <i className="bi bi-whatsapp">
          {" "}
          <p>START CHAT!</p>
        </i>
      </div>
    </a>
  );
};

export default WAButton;
